
import { defineComponent, ref } from 'vue'
import ServicesByocFilter from '@/components/pages/services/byoc/list/ServicesByocFilter.vue'
import LinkNumbersTable from '@/components/pages/chats/settings/channels/flow/linkNumbers/LinkNumbersTable.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import { NumbersMock } from '@/definitions/services/byoc/data'

export default defineComponent({
  components: {
    ServicesByocFilter,
    LinkNumbersTable,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Number', value: 'number-slot' },
      { text: 'Label in Textmagic', value: 'label-slot' },
      { text: 'Calls', value: 'voice-slot', width: '80px' },
      { text: 'SMS', value: 'sms-slot', width: '80px' },
      { text: 'MMS', value: 'mms-slot', width: '160px' },
      { text: 'Provider', value: 'provider-slot' },
      { text: 'Forward calls to', value: 'forward-slot' },
    ])
    const tableItems = ref(NumbersMock)

    return {
      isEmptyMode,
      search,
      tableHeaders,
      tableItems,
    }
  },
})
